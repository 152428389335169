import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { HandleErrorModel } from '../../core/models/handle-error.model';
import { AuthApiService } from './auth-api.service';
import * as act from './auth.actions';

@Injectable()
export class AuthEffects {
    constructor(private service: AuthApiService, private actions$: Actions) {}

    @Effect()
    efetuarLoginAction$ = this.actions$.pipe(
        ofType<act.EfetuarLoginAction>(act.AuthActions.EFETUAR_LOGIN),
        map((action) => action.payload),
        switchMap((payload) =>
            this.service
                .efetuarLoginFirebase(payload.email, payload.senha)
                .pipe(
                    mergeMap((res) => {
                        return [new act.RecuperarLoginAction()];
                    }),
                    catchError((error) =>
                        of(new act.LoginNaoEfetuadoAction(error))
                    )
                )
        )
    );

    // @Effect()
    // efetuarLoginGoogleAction$ = this.actions$
    //     .ofType<act.EfetuarLoginGoogleAction>(act.AuthActions.EFETUAR_LOGIN_GOOGLE)
    //     .switchMap(payload =>
    //         this.service
    //             .efetuarLoginGoogle()
    //             .map(res => new act.RecuperarLoginAction())
    //             .catch(error => Observable.of(new act.LoginNaoEfetuadoAction(error)))
    //     );

    @Effect()
    efetuarLoginInternoAction$ = this.actions$.pipe(
        ofType<act.EfetuarLoginInternoAction>(
            act.AuthActions.EFETUAR_LOGIN_INTERNO
        ),
        map((action) => action.payload),
        switchMap((payload) =>
            this.service.efetuarLoginInterno(payload.email, payload.senha).pipe(
                mergeMap((res) => {
                    return [
                        new act.LoginEfetuadoComSucessoAction(res),
                        new act.AutenticarAction(payload.email),
                    ];
                }),
                catchError((error) => of(new act.LoginNaoEfetuadoAction(error)))
            )
        )
    );

    @Effect()
    recuperarLoginAction$ = this.actions$.pipe(
        ofType<act.RecuperarLoginAction>(act.AuthActions.RECUPERAR_LOGIN),
        switchMap(() =>
            this.service.obterInformacoesDoLogin().pipe(
                mergeMap((res) => {
                    return [
                        new act.LoginRecuperadoComSucessoAction(res),
                        new act.AutenticarAction(
                            res && res.email ? res.email : null
                        ),
                    ];
                }),
                catchError((error) => this.handleError(error))
            )
        )
    );

    @Effect()
    autenticarAction$ = this.actions$.pipe(
        ofType<act.AutenticarAction>(act.AuthActions.AUTENTICAR),
        map((action) => action.payload),
        filter((payload) => payload),
        switchMap((payload) =>
            this.service.autenticar(payload).pipe(
                mergeMap((res) => {
                    return [
                        new act.AutenticadoComSucessoAction(res),
                        new act.RegistrarLoginAction(),
                        new act.DadosFornecedorCarregarAction(payload),
                    ];
                }),
                catchError((error) => this.handleError(error))
            )
        )
    );

    @Effect()
    trocarEmailAction$ = this.actions$.pipe(
        ofType<act.TrocarEmailAction>(act.AuthActions.TROCAR_EMAIL),
        map((action) => action.payload),
        filter((payload) => payload),
        switchMap((payload) =>
            this.service.obterDadosDoFornecedorPorEmail(payload).pipe(
                map(
                    (res) =>
                        new act.DadosFornecedorCarregarComSucessoAction(res)
                ),
                catchError(() =>
                    of(new act.DadosFornecedorCarregarComSucessoAction(null))
                )
            )
        )
    );

    @Effect()
    dadosFornecedorCarregarAction$ = this.actions$.pipe(
        ofType<act.DadosFornecedorCarregarAction>(
            act.AuthActions.DADOS_FORNECEDOR_CARREGAR
        ),
        map((action) => action.payload),
        filter((payload) => payload),
        switchMap((payload) =>
            this.service.obterDadosDoFornecedorPorEmail(payload).pipe(
                map(
                    (res) =>
                        new act.DadosFornecedorCarregarComSucessoAction(res)
                ),
                catchError(() =>
                    of(new act.DadosFornecedorCarregarComSucessoAction(null))
                )
            )
        )
    );

    @Effect()
    registrarLoginAction$ = this.actions$.pipe(
        ofType<act.RegistrarLoginAction>(act.AuthActions.REGISTRAR_LOGIN),
        switchMap(() =>
            this.service.registrarLogin().pipe(
                map((res) => new act.LoginRegistradoComSucessoAction()),
                catchError((error) =>
                    of(new act.LoginNaoRegistradoComSucessoAction())
                )
            )
        )
    );

    @Effect()
    efetuarLogoutAction$ = this.actions$.pipe(
        ofType<act.EfetuarLogoutAction>(act.AuthActions.EFETUAR_LOGOUT),
        switchMap(() =>
            this.service.efetuarLogout().pipe(
                map((res) => new act.LogoutEfetuadoAction()),
                catchError((error) => this.handleError(error))
            )
        )
    );

    @Effect()
    registrarLogoutAction$ = this.actions$.pipe(
        ofType<act.RegistrarLogoutAction>(act.AuthActions.REGISTRAR_LOGOUT),
        switchMap(() =>
            this.service.registrarLogout().pipe(
                map((res) => new act.LogoutRegistradoComSucessoAction()),
                catchError((error) =>
                    of(new act.LogoutNaoRegistradoComSucessoAction())
                )
            )
        )
    );

    @Effect()
    criarAcessoAction$ = this.actions$.pipe(
        ofType<act.CriarAcessoAction>(act.AuthActions.CRIAR_ACESSO),
        map((action) => action.payload),
        switchMap((payload) =>
            this.service.criarNovaConta(payload.email, payload.senha).pipe(
                map((res) => new act.CriarAcessoComSucessoAction()),
                catchError((error) => this.handleError(error))
            )
        )
    );

    @Effect()
    resetarSenhaAction$ = this.actions$.pipe(
        ofType<act.ResetarSenhaAction>(act.AuthActions.RESETAR_SENHA),
        map((action) => action.payload),
        switchMap((payload) =>
            this.service.resetarSenha(payload).pipe(
                map((res) => new act.SenhaResetadaAction()),
                catchError((error) => this.handleError(error))
            )
        )
    );

    @Effect()
    redefinirSenhaAction$ = this.actions$.pipe(
        ofType<act.RedefinirSenhaAction>(act.AuthActions.REDEFINIR_SENHA),
        map((action) => action.payload),
        switchMap((payload) =>
            this.service.redefinirSenha(payload.code, payload.senha).pipe(
                map((res) => new act.SenhaRedefinidaAction()),
                catchError((error) => this.handleError(error))
            )
        )
    );

    private handleError(error: any) {
        console.log('ERROR AUTH=', error);
        let e = new HandleErrorModel(error);
        return of(new act.ErroAction(e));
    }
}
