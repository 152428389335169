import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-message-dialog',
    templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent implements OnInit {
    public title: string;
    public message: string;

    constructor(public dialogRef: MatDialogRef<MessageDialogComponent>) {}

    ngOnInit(): void {}
}
