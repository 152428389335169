import { Injectable, ViewContainerRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar'; // Corrigido o import
import { Observable } from 'rxjs';
import { SnackBarMessageComponent } from '../snack-message/snack-bar-message.component';
import notify from 'devextreme/ui/notify';

@Injectable()
export class MessageService {
    constructor(private _snackBar: MatSnackBar) {}

    growlMessage(message: any, duration: number = 5000) {
        // this._appService.setFinishedProcessing();

        if (!message || !message.severity || !message.detail) {
            return;
        }

        let icon = 'info';
        let iconClass = 'info-icon';

        let action = 'OK';

        if (message.severity == 'error') {
            action = 'ERRO';
            iconClass = 'error-icon';
        } else if (message.severity === 'success') {
            icon = 'check_circle';
            iconClass = 'success-icon';
        }

        let snackBarRef = this._snackBar.openFromComponent(
            SnackBarMessageComponent,
            {
                duration: duration,
                data: {
                    icon: icon,
                    message: message.detail,
                    iconClass: iconClass,
                },
                verticalPosition: 'top',
                horizontalPosition: 'end',
                panelClass: 'growl-message',
            }
        );

        return snackBarRef.afterDismissed();
    }

    simpleMessage(
        message: string,
        duration: number = 5000,
        viewContainerRef: ViewContainerRef = null
    ): Observable<any> {
        let snackBarRef = this._snackBar.openFromComponent(
            SnackBarMessageComponent,
            {
                duration: duration,
                data: {
                    icon: 'info',
                    message: message,
                    iconClass: 'info-icon',
                },
                viewContainerRef: viewContainerRef,
                verticalPosition: 'top',
                horizontalPosition: 'end',
                panelClass: 'simple-message',
            }
        );
        return snackBarRef.afterDismissed();
    }

    errorMessage(
        e: any,
        duration: number = 5000,
        viewContainerRef: ViewContainerRef = null
    ) {
        // this._appService.setFinishedProcessing();
        let message: string = '';
        if (e && e.errorMessage) {
            message = e.errorMessage;
        } else {
            if (e.status && e.statusText) {
                message = e.status + ' - ' + e.statusText;
            }
        }

        if (message == '') {
            message = 'Atenção!!! Ocorreu um erro.';
        }

        let snackBarRef = this._snackBar.openFromComponent(
            SnackBarMessageComponent,
            {
                duration: duration,
                data: {
                    icon: 'error',
                    message: message,
                    iconClass: 'error-icon',
                },
                viewContainerRef: viewContainerRef,
                verticalPosition: 'top',
                horizontalPosition: 'end',
                panelClass: 'error-message',
            }
        );
        return snackBarRef.afterDismissed();
    }

    successMessage(
        e: any,
        duration: number = 5000,
        viewContainerRef: ViewContainerRef = null
    ) {
        // this._appService.setFinishedProcessing();
        let message: string = '';
        if (e && e.successMessage) {
            message = e.successMessage;
        } else {
            if (e.status && e.statusText) {
                message = e.status + ' - ' + e.statusText;
            }
        }

        let snackBarRef = this._snackBar.openFromComponent(
            SnackBarMessageComponent,
            {
                duration: duration,
                data: {
                    icon: 'success',
                    message: message,
                    iconClass: 'success-icon',
                },
                viewContainerRef: viewContainerRef,
                verticalPosition: 'top',
                horizontalPosition: 'end',
                panelClass: 'success-message',
            }
        );
        return snackBarRef.afterDismissed();
    }

    notificationSuccessMessage(
        message: string,
        duration: number = 100000,
        width: string = '300px',
        height: string = '80px'
    ) {
        notify(
            {
                message: message,
                width: width,
                height: height,
                position: {
                    at: 'top right',
                    my: 'top right',
                    offset: '-10 50',
                },
                shading: false,
                closeOnOutsideClick: true,
            },
            'success',
            duration
        );
    }

    notificationErrorMessage(
        message: string,
        duration: number = 3000,
        width: string = 'fit-content',
        height: string = '80px'
    ) {
        notify(
            {
                message: message,
                width: width,
                height: height,
                position: {
                    at: 'top right',
                    my: 'top right',
                    offset: '-10 50',
                },
                shading: false,
                closeOnOutsideClick: true,
            },
            'error',
            duration
        );
    }
}
