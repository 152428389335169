// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    local: false,

    otkerpApi: 'https://otkwebdevapi.azurewebsites.net/api', // API - DEV

    otkerpApiAuthWeb: 'https://otkwebdevauthweb.azurewebsites.net/api', // API AUTH WEB - DEV

    otkerpApiGeneral: 'https://otkwebdevgeneralapi.azurewebsites.net/api', // API GENERAL - DEV

    otkerpApiPurchase: 'https://otkwebdevpurchaseapi.azurewebsites.net/api', // API COMPRAS - DEV

    otkerpApiReports: 'https://otkwebdevreportsapi.azurewebsites.net/api', // API - REPORTS (DEV)

    firebaseConfig: {
        apiKey: 'AIzaSyAN10ylxas8Shh-0FKaxQiWPxu7bAZCaKQ',
        authDomain: 'otk-web.firebaseapp.com',
        databaseURL: 'https://otk-web.firebaseio.com',
        projectId: 'otk-web',
        storageBucket: 'otk-web.appspot.com',
        messagingSenderId: '466876662675',
    },
};
