import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Headers } from '@angular/http';
import { Subject } from 'rxjs';

import { environment } from './../environments/environment';

declare var sessionStorage: any;

export class AppSettings {
    private static readonly OTK_MENU_OPEN = 'OTK_MENU_OPEN';
    private static readonly OTK_REMEMBER_EMAIL = 'OTK_REMEMBER_EMAIL';
    private static readonly OTK_REMEMBER_TOKEN = 'OTK_REMEMBER_TOKEN';
    static token$: Subject<string> = new Subject<string>();

    public static get CONST_OTK_TOKEN(): string {
        return 'OTK_TOKEN';
    }
    public static get CONST_OTK_REFRESH_TOKEN(): string {
        return 'OTK_REFRESH_TOKEN';
    }
    public static get APP_SISTEMA(): string {
        return 'COTACOES';
    }

    public static get APP_VERSION(): string {
        let v = 'v4.0.0';

        if (!environment.production) {
            if (environment.local) {
                v = `${v} (DEV-LOCAL)`;
            } else {
                v = `${v} (DEV)`;
            }
        }

        return v;
    }

    public static get APP_LOCAL_MODE(): boolean {
        if (!environment.production) {
            return environment.local;
        }
        return false;
    }

    public static get APP_DEV_MODE(): boolean {
        return !environment.production;
    }

    public static get API_ENDPOINT(): string {
        return environment.otkerpApi;
    }

    public static get API_ENDPOINT_AUTH(): string {
        return environment.otkerpApiAuth;
    }

    public static get API_ENDPOINT_GENERAL(): string {
        return environment.otkerpApiGeneral;
    }

    public static get API_ENDPOINT_COMPRAS(): string {
        return environment.otkerpApiPurchase;
    }

    public static get API_ENDPOINT_RELATORIOS(): string {
        return environment.otkerpApiReports;
    }

    public static get URL_TEMPFILES(): string {
        let r = this.API_ENDPOINT;
        r = r.replace(/\/api/g, '');
        return r + '/TempFiles';
    }

    public static get URL_TEMPFILES_RELATORIOS(): string {
        let r = this.API_ENDPOINT_RELATORIOS;
        r = r.replace(/\/api/g, '');
        return r + '/TempFiles';
    }

    public static get AUTHORIZATION_HEADERS(): Headers {
        let token = this.recuperarToken();

        let headers = new Headers();
        headers.set('Authorization', `Bearer ${token}`);
        headers.set('Pragma', 'no-cache');
        headers.set('Cache-Control', 'no-cache');

        return headers;
    }

    public static get HTTP_HEADERS_AUTHORIZATION(): HttpHeaders {
        let token = this.recuperarToken();

        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${token}`)
            .set('Pragma', 'no-cache')
            .set('Cache-Control', 'no-cache');

        return headers;
    }

    public static HTTP_PARAMS_CLIENT_CREDENTIALS(email: string): HttpParams {
        const params = new HttpParams()
            .set('client_id', 'COTACOES')
            .set('client_secret', 'b3Rrc2lzdGVtYXNfcG9ydGFsX2NvdGFjb2Vz')
            .set('grant_type', 'client_credentials')
            .set('username', email);

        return params;
    }

    public static armazenarToken(token: string, refreshtoken: string): void {
        this.limparToken();
        if (token.length > 0) {
            sessionStorage.setItem(AppSettings.CONST_OTK_TOKEN, token);
            sessionStorage.setItem(
                AppSettings.CONST_OTK_REFRESH_TOKEN,
                refreshtoken
            );
            this.token$.next(token);
        }
    }
    public static recuperarToken(): string {
        return sessionStorage.getItem(AppSettings.CONST_OTK_TOKEN);
    }
    public static limparToken(): void {
        this.token$.next(null);
        sessionStorage.removeItem(AppSettings.CONST_OTK_TOKEN);
    }

    public static armazenarMenuOpen(open: boolean): void {
        localStorage.setItem(this.OTK_MENU_OPEN, open.toString());
    }
    public static recuperarMenuOpen(): boolean {
        let open = localStorage.getItem(this.OTK_MENU_OPEN);
        if (open === null || open === undefined) {
            this.armazenarMenuOpen(false);
            return false;
        }
        return open == 'true';
    }

    public static recuperarLogin(): string {
        let email = localStorage.getItem(this.OTK_REMEMBER_EMAIL);
        let token = localStorage.getItem(this.OTK_REMEMBER_TOKEN);
        if (email && token) {
            this.armazenarToken(token, null);
        }
        return email;
    }

    public static esquecerLogin(email: boolean = false): void {
        if (email) {
            localStorage.removeItem(this.OTK_REMEMBER_EMAIL);
        }

        localStorage.removeItem(this.OTK_REMEMBER_TOKEN);
    }

    public static lembrarLogin(email): void {
        localStorage.setItem(this.OTK_REMEMBER_EMAIL, email);
        localStorage.setItem(this.OTK_REMEMBER_TOKEN, this.recuperarToken());
    }
}
