import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { map, tap, timeout } from 'rxjs/operators';
import { Observable } from 'rxjs/Rx';

import { AuthUser } from '../models/auth-user.model';
import { AppSettings } from './../../app.settings';
import { FireAuthService } from './../../firebase/services/fire-auth.service';

@Injectable()
export class AuthApiService {
    constructor(
        private _httpClient: HttpClient,
        private _authfire: FireAuthService
    ) {}

    obterInformacoesDoLogin(): Observable<any> {
        return this._authfire.user$.pipe(
            map((r) => {
                if (r && r.email) {
                    let usr = new AuthUser();
                    usr.codigoDoUsuario = r.email;
                    usr.nome = r.displayName ? r.displayName : r.email;
                    usr.nomeDoColaborador = usr.nome;
                    usr.email = r.email;
                    usr.autenticado = true;
                    usr.administrador = true;
                    usr.imagem = r.photoURL;
                    return usr;
                }
                // throw new Error('User invalid!')
            })
        );
    }

    obterDadosDoFornecedorPorEmail(email: string): Observable<any> {
        let params = new HttpParams();
        params = params.set('email', email);

        return this._httpClient.get(
            `${AppSettings.API_ENDPOINT_COMPRAS}/compras/cotacaofornecedor/cadastro/verificar`,
            { params }
        );
    }

    efetuarLoginInterno(email: string, senha: string): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set(
            'Content-Type',
            `application/x-www-form-urlencoded`
        );

        const params = new HttpParams()
            .set('username', email)
            .set('password', senha);
        return this._httpClient
            .post<any>(
                `${AppSettings.API_ENDPOINT_AUTH_WEB}/autenticacao/portal-fornecedor/validar-login-interno`,
                params.toString(),
                { headers }
            )
            .pipe(
                map((r) => {
                    let usr = new AuthUser();
                    usr.codigoDoUsuario = email;
                    usr.nome = email;
                    usr.nomeDoColaborador = email;
                    usr.email = email;
                    usr.autenticado = r;
                    usr.loginInterno = true;
                    usr.administrador = true;
                    usr.imagem = null;
                    return usr;
                })
            );
    }

    autenticar(email: string): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set(
            'Content-Type',
            `application/x-www-form-urlencoded`
        );

        let params = AppSettings.HTTP_PARAMS_CLIENT_CREDENTIALS(email);

        return this._httpClient
            .post<any>(
                `${AppSettings.API_ENDPOINT_AUTH_WEB}/autenticacao/portal-fornecedor/client-credentials`,
                params.toString(),
                { headers }
            )
            .pipe(
                tap(() => AppSettings.limparToken()),
                map((r) => {
                    if (r) {
                        AppSettings.armazenarToken(
                            r.access_token,
                            r.refresh_token
                        );
                        return r.access_token;
                    }
                })
            );
    }

    efetuarLoginFirebase(email: string, senha: string): Observable<any> {
        return from(
            this._authfire.signIn(email, senha).then((r) => {
                return r;
            })
        );
    }

    efetuarLogout(): Observable<any> {
        return from(this._authfire.signOut());
    }

    criarNovaConta(email: string, senha: string): Observable<any> {
        return from(this._authfire.createUser(email, senha));
    }

    resetarSenha(email: string): Observable<any> {
        return from(this._authfire.resetPassword(email));
    }

    redefinirSenha(code: any, senha: any): Observable<any> {
        return from(this._authfire.confirmResetPassword(code, senha));
    }

    private get _informacoesDoSistema(): any {
        return {
            sistema: AppSettings.APP_SISTEMA,
            versao: AppSettings.APP_VERSION,
        };
    }

    registrarLogin() {
        return this._httpClient
            .post<any>(
                `${AppSettings.API_ENDPOINT_GENERAL}/sistema/registrar-login-cotacao`,
                this._informacoesDoSistema
            )
            .pipe(timeout(10000));
    }

    registrarLogout() {
        return this._httpClient
            .post<any>(
                `${AppSettings.API_ENDPOINT_GENERAL}/sistema/registrar-logout-cotacao`,
                this._informacoesDoSistema
            )
            .pipe(timeout(10000));
    }

    // efetuarLoginGoogle(): Observable<any> {
    //     return Observable.fromPromise(
    //         this._authfire.googleLogin()
    //             .then(r => {
    //                 // AppSettings.armazenarToken(r);
    //                 return r;
    //             })
    //     );
    // }

    // habilitarFireMessaging(): void {
    //     this._authfire.user$
    //         .filter(user => !!user) // filter null
    //         .take(1) // take first real user
    //         .subscribe(user => {
    //             if (user) {
    //                 this._messagingFire.getPermission(user)
    //                 this._messagingFire.monitorRefresh(user)
    //                 this._messagingFire.receiveMessages()
    //             }
    //         });
    // }
}
